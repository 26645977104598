const Translations = {
	id:{
		"hr":"hr",
		//Employee
		"Employee":"Karyawan",
		"employee":"Karyawan",
		"employee.contract":"Buat Kontrak",
		"Employee.confirmTitle.contract":"Buat Kontrak",
		"Employee.confirmContent.contract":"Buat Kontrak?",
		"Employee.success.contract":"Karyawan berhasil",
		"employee.warn":"Surat Peringatan",
		"Employee.confirmTitle.warn":"Surat Peringatan",
		"Employee.confirmContent.warn":"Surat Peringatan?",
		"Employee.success.warn":"Karyawan berhasil",
		"employee.terminate":"Berhentikan",
		"Employee.confirmTitle.terminate":"Berhentikan",
		"Employee.confirmContent.terminate":"Berhentikan?",
		"Employee.success.terminate":"Karyawan berhasil",
		"Employee.number":"Nomor",
		"Employee.name":"Nama",
		"Employee.managerId":"Atasan",
		"Employee.manager":"Atasan",
		"Employee.indirectManagerId":"Atasan Tidak Langsung",
		"Employee.indirectManager":"Atasan Tidak Langsung",
		"Employee.employerId":"Penggajian",
		"Employee.employer":"Penggajian",
		"Employee.branchId":"Cabang",
		"Employee.branch":"Cabang",
		"Employee.departmentId":"Departemen",
		"Employee.department":"Departemen",
		"Employee.sectionId":"Bagian",
		"Employee.section":"Bagian",
		"Employee.positionId":"Posisi",
		"Employee.position":"Posisi",
		"Employee.levelId":"Tingkatan",
		"Employee.level":"Tingkatan",
		"Employee.joinDate":"Tanggal Mulai Kerja",
		"Employee.joinAge":"Lama Bekerja",
		"Employee.contractId":"Kontrak",
		"Employee.contract":"Kontrak",
		"Employee.activeDays":"Sisa Hari",
		"Employee.placeOfBirth":"Tempat Kelahiran",
		"Employee.dateOfBirth":"Tanggal Kelahiran",
		"Employee.currentAge":"Umur",
		"Employee.sex":"Jenis Kelamin",
		"Employee.bloodType":"Golongan Darah",
		"Employee.ktp":"KTP",
		"Employee.ktpExpiryDate":"KTP Berlaku Sampai",
		"Employee.ktpAttachment":"Lampiran KTP",
		"Employee.address":"Alamat",
		"Employee.ktpAddress":"Alamat KTP",
		"Employee.city":"Kota",
		"Employee.office":"Alamat Kantor",
		"Employee.phone":"No. Telefon",
		"Employee.mobile":"No. HP",
		"Employee.email":"Email",
		"Employee.experiences":"Pengalaman Kerja",
		"Employee.educations":"Pendidikan",
		"Employee.religionId":"Agama",
		"Employee.religion":"Agama",
		"Employee.mother":"Ibu Kandung",
		"Employee.sibling":"Saudara Kandung",
		"Employee.maritalStatus":"Status Pernikahan",
		"Employee.spouse":"Suami / Istri",
		"Employee.spouseDateOfBirth":"Tanggal Lahir Suami / Istri",
		"Employee.spouseAge":"Umur",
		"Employee.childrenCount":"Jumlah Anak",
		"Employee.children":"Daftar Anak",
		"Employee.types":"Tipe",
		"Employee.shiftTemplateId":"Template Shift",
		"Employee.shiftTemplate":"Template Shift",
		"Employee.leaveBalance":"Sisa",
		"Employee.leaveQuotas":"Daftar Kuota Cuti",
		"Employee.leaves":"Cuti / Ijin",
		"Employee.rollingOffs":"Daftar Cuti Bergilir",
		"Employee.payrollCode":"Kode Payroll",
		"Employee.bankAccount":"Bank Account",
		"Employee.salary":"Upah",
		"Employee.npwp":"NPWP",
		"Employee.npwpAddress":"Alamat NPWP",
		"Employee.taxMother":"Ibu Kandung (Pajak)",
		"Employee.taxSibling":"Saudara",
		"Employee.taxSpouse":"Suami / Istri (pajak)",
		"Employee.taxSpouseDateOfBirth":"Tanggal Lahir Suami / Istri (Pajak)",
		"Employee.taxSpouseAge":"Umur Suami / Istri (Pajak)",
		"Employee.taxChildrenCount":"Jumlah Anak (Pajak)",
		"Employee.taxChildren":"Daftar Anak (Pajak)",
		"Employee.kartuKeluargaNumber":"Nomor Kartu Keluarga",
		"Employee.kartuKeluargaAttachment":"Scan Kartu Keluarga",
		"Employee.kartuKpjBpjstkNumber":"BPJS Ketenagakerjaan",
		"Employee.kartuBpjsKesehatanNumber":"BPJS Kesehatan",
		"Employee.contracts":"Sejarah Kontrak",
		"Employee.employers":"Sejarah Penggajian",
		"Employee.branches":"Sejarah Cabang",
		"Employee.departments":"Sejarah Departemen",
		"Employee.positions":"Sejarah Posisi",
		"Employee.sections":"Sejarah Bagian",
		"Employee.levels":"Sejarah Tingkatan",
		"Employee.userId":"Akun Pengguna",
		"Employee.user":"Akun Pengguna",
		"Employee.fingerprintId":"Fingerprint",
		"Employee.fingerprint":"Fingerprint",
		"Employee.state":"Status",
		"Employee.proposeDate":"Tanggal Melamar",
		"Employee.notices":"Surat Peringatan",
		"Employee.griefDescription":"Keterangan Dukacita",
		"Employee.unbound":"Tidak Terikat",
		"Employee.notes":"Catatan",
		//Religion
		"Religion":"Agama",
		"religion":"Agama",
		"religion.activate":"Aktifkan",
		"Religion.confirmTitle.activate":"Aktifkan",
		"Religion.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Agama ini?",
		"Religion.success.activate":"Agama telah berhasil diaktifkan",
		"religion.deactivate":"Nonaktifkan",
		"Religion.confirmTitle.deactivate":"Nonaktifkan",
		"Religion.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Agama ini?",
		"Religion.success.deactivate":"Agama telah berhasil dinonaktifkan",
		"Religion.name":"Nama",
		"Religion.state":"Status",
		//Contract
		"Contract":"Kontrak",
		"contract":"Kontrak",
		"contract.activate":"Aktifkan",
		"Contract.confirmTitle.activate":"Aktifkan",
		"Contract.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Kontrak ini?",
		"Contract.success.activate":"Kontrak telah berhasil diaktifkan",
		"contract.deactivate":"Nonaktifkan",
		"Contract.confirmTitle.deactivate":"Nonaktifkan",
		"Contract.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Kontrak ini?",
		"Contract.success.deactivate":"Kontrak telah berhasil dinonaktifkan",
		"Contract.name":"Nomor",
		"Contract.contractType":"Tipe Kontrak",
		"Contract.dateStart":"Tanggal Mulai",
		"Contract.dateEnd":"Tanggal Selesai",
		"Contract.current":"Aktif",
		"Contract.activeDays":"Masa Aktif (hari)",
		"Contract.notifyContract":"Notifikasi",
		"Contract.description":"Deskripsi",
		"Contract.employeeId":"Pegawai",
		"Contract.employee":"Pegawai",
		"Contract.state":"Status",
		//Branch
		"Branch":"Cabang",
		"branch":"Cabang",
		"branch.activate":"Aktifkan",
		"Branch.confirmTitle.activate":"Aktifkan",
		"Branch.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Cabang ini?",
		"Branch.success.activate":"Cabang telah berhasil diaktifkan",
		"branch.deactivate":"Nonaktifkan",
		"Branch.confirmTitle.deactivate":"Nonaktifkan",
		"Branch.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Cabang ini?",
		"Branch.success.deactivate":"Cabang telah berhasil dinonaktifkan",
		"Branch.name":"Nama",
		"Branch.representativeId":"Representasi",
		"Branch.representative":"Representasi",
		"Branch.clientId":"Klien Fingerprint",
		"Branch.client":"Klien Fingerprint",
		"Branch.lateTolerance":"Toleransi Keterlambatan",
		"Branch.earlyTolerance":"Toleransi Pulang Lebih Cepat",
		"Branch.state":"Status",
		//Employee Branch
		"EmployeeBranch":"Cabang Pegawai",
		"employee_branch":"Cabang Pegawai",
		"employee_branch.activate":"Aktifkan",
		"EmployeeBranch.confirmTitle.activate":"Aktifkan",
		"EmployeeBranch.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Cabang Pegawai ini?",
		"EmployeeBranch.success.activate":"Cabang Pegawai telah berhasil diaktifkan",
		"employee_branch.deactivate":"Nonaktifkan",
		"EmployeeBranch.confirmTitle.deactivate":"Nonaktifkan",
		"EmployeeBranch.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Cabang Pegawai ini?",
		"EmployeeBranch.success.deactivate":"Cabang Pegawai telah berhasil dinonaktifkan",
		"EmployeeBranch.dateStart":"Tanggal Mulai",
		"EmployeeBranch.dateEnd":"Tanggal Selesai",
		"EmployeeBranch.current":"Aktif",
		"EmployeeBranch.description":"Deskripsi",
		"EmployeeBranch.branchId":"Cabang",
		"EmployeeBranch.branch":"Cabang",
		"EmployeeBranch.employeeId":"Pegawai",
		"EmployeeBranch.employee":"Pegawai",
		"EmployeeBranch.state":"Status",
		//Employer
		"Employer":"Pemberi Upah",
		"employer":"Pemberi Upah",
		"employer.activate":"Aktifkan",
		"Employer.confirmTitle.activate":"Aktifkan",
		"Employer.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Pemberi Upah ini?",
		"Employer.success.activate":"Pemberi Upah telah berhasil diaktifkan",
		"employer.deactivate":"Nonaktifkan",
		"Employer.confirmTitle.deactivate":"Nonaktifkan",
		"Employer.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Pemberi Upah ini?",
		"Employer.success.deactivate":"Pemberi Upah telah berhasil dinonaktifkan",
		"Employer.name":"Nama",
		//Employee Employer
		"EmployeeEmployer":"Pemberi Upah Pegawai",
		"employee_employer":"Pemberi Upah Pegawai",
		"EmployeeEmployer.dateStart":"Tanggal Mulai",
		"EmployeeEmployer.dateEnd":"Tanggal Selesai",
		"EmployeeEmployer.current":"Aktif",
		"EmployeeEmployer.description":"Deskripsi",
		"EmployeeEmployer.employerId":"Pemberi Upah",
		"EmployeeEmployer.employer":"Pemberi Upah",
		"EmployeeEmployer.employeeId":"Pegawai",
		"EmployeeEmployer.employee":"Pegawai",
		"EmployeeEmployer.state":"Status",
		//Level
		"Level":"Tingkatan",
		"level":"Tingkatan",
		"level.activate":"Aktifkan",
		"Level.confirmTitle.activate":"Aktifkan",
		"Level.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Tingkatan ini?",
		"Level.success.activate":"Tingkatan telah berhasil diaktifkan",
		"level.deactivate":"Nonaktifkan",
		"Level.confirmTitle.deactivate":"Nonaktifkan",
		"Level.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Tingkatan ini?",
		"Level.success.deactivate":"Tingkatan telah berhasil dinonaktifkan",
		"Level.name":"Nama",
		"Level.state":"Status",
		//Employee Level
		"EmployeeLevel":"Tingkatan Pegawai",
		"employee_level":"Tingkatan Pegawai",
		"EmployeeLevel.dateStart":"Tanggal Mulai",
		"EmployeeLevel.dateEnd":"Tanggal Selesai",
		"EmployeeLevel.current":"Aktif",
		"EmployeeLevel.description":"Deskripsi",
		"EmployeeLevel.levelId":"Level",
		"EmployeeLevel.level":"Level",
		"EmployeeLevel.employeeId":"Pegawai",
		"EmployeeLevel.employee":"Pegawai",
		"EmployeeLevel.state":"Status",
		//Position
		"Position":"Posisi",
		"position":"Posisi",
		"position.activate":"Aktifkan",
		"Position.confirmTitle.activate":"Aktifkan",
		"Position.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Posisi ini?",
		"Position.success.activate":"Posisi telah berhasil diaktifkan",
		"position.deactivate":"Nonaktifkan",
		"Position.confirmTitle.deactivate":"Nonaktifkan",
		"Position.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Posisi ini?",
		"Position.success.deactivate":"Posisi telah berhasil dinonaktifkan",
		"Position.name":"Nama",
		"Position.state":"Status",
		//Employee Position
		"EmployeePosition":"Posisi Pegawai",
		"employee_position":"Posisi Pegawai",
		"EmployeePosition.dateStart":"Tanggal Mulai",
		"EmployeePosition.dateEnd":"Tanggal Selesai",
		"EmployeePosition.current":"Aktif",
		"EmployeePosition.description":"Deskripsi",
		"EmployeePosition.positionId":"Posisi",
		"EmployeePosition.position":"Posisi",
		"EmployeePosition.employeeId":"Pegawai",
		"EmployeePosition.employee":"Pegawai",
		"EmployeePosition.state":"Status",
		//Department
		"Department":"Departemen",
		"department":"Departemen",
		"department.activate":"Aktifkan",
		"Department.confirmTitle.activate":"Aktifkan",
		"Department.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Departemen ini?",
		"Department.success.activate":"Departemen telah berhasil diaktifkan",
		"department.deactivate":"Nonaktifkan",
		"Department.confirmTitle.deactivate":"Nonaktifkan",
		"Department.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Departemen ini?",
		"Department.success.deactivate":"Departemen telah berhasil dinonaktifkan",
		"Department.name":"Nama",
		"Department.state":"Status",
		//Employee Department
		"EmployeeDepartment":"Departemen Pegawai",
		"employee_department":"Departemen Pegawai",
		"EmployeeDepartment.dateStart":"Tanggal Mulai",
		"EmployeeDepartment.dateEnd":"Tanggal Selesai",
		"EmployeeDepartment.current":"Aktif",
		"EmployeeDepartment.description":"Deskripsi",
		"EmployeeDepartment.departmentId":"Departemen",
		"EmployeeDepartment.department":"Departemen",
		"EmployeeDepartment.employeeId":"Pegawai",
		"EmployeeDepartment.employee":"Pegawai",
		"EmployeeDepartment.state":"Status",
		//Section
		"Section":"Bagian",
		"section":"Bagian",
		"section.activate":"Aktifkan",
		"Section.confirmTitle.activate":"Aktifkan",
		"Section.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Bagian ini?",
		"Section.success.activate":"Bagian telah berhasil diaktifkan",
		"section.deactivate":"Nonaktifkan",
		"Section.confirmTitle.deactivate":"Nonaktifkan",
		"Section.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Bagian ini?",
		"Section.success.deactivate":"Bagian telah berhasil dinonaktifkan",
		"Section.name":"Nama",
		"Section.state":"Status",
		//Employee Section
		"EmployeeSection":"Bagian Pegawai",
		"employee_section":"Bagian Pegawai",
		"EmployeeSection.dateStart":"Tanggal Mulai",
		"EmployeeSection.dateEnd":"Tanggal Selesai",
		"EmployeeSection.current":"Aktif",
		"EmployeeSection.description":"Deskripsi",
		"EmployeeSection.sectionId":"Bagian",
		"EmployeeSection.section":"Bagian",
		"EmployeeSection.employeeId":"Pegawai",
		"EmployeeSection.employee":"Pegawai",
		"EmployeeSection.state":"Status",
		//Employee Child
		"EmployeeChild":"Anak Pegawai",
		"employee_child":"Anak Pegawai",
		"EmployeeChild.name":"Nama",
		"EmployeeChild.dateOfBirth":"Tanggal Lahir",
		"EmployeeChild.age":"Umur",
		"EmployeeChild.covered":"Ditanggung",
		"EmployeeChild.employeeId":"Pegawai",
		"EmployeeChild.employee":"Pegawai",
		//Employee Experience
		"EmployeeExperience":"Pengalaman Kerja Pegawai",
		"employee_experience":"Pengalaman Kerja Pegawai",
		"EmployeeExperience.company":"Perusahaan",
		"EmployeeExperience.startPeriod":"Tanggal Mulai",
		"EmployeeExperience.endPeriod":"Tanggal Selesai",
		"EmployeeExperience.description":"Deskripsi",
		"EmployeeExperience.employeeId":"Pegawai",
		"EmployeeExperience.employee":"Pegawai",
		//Employee Education
		"EmployeeEducation":"Pendidikan Pegawai",
		"employee_education":"Pendidikan Pegawai",
		"EmployeeEducation.employeeId":"Pegawai",
		"EmployeeEducation.employee":"Pegawai",
		"EmployeeEducation.degree":"Tingkatan",
		"EmployeeEducation.major":"Jurusan",
		"EmployeeEducation.insitution":"Institusi",
		//Warning Notice
		"WarningNotice":"Surat Peringatan",
		"warning_notice":"Surat Peringatan",
		"warning_notice.send":"Kirim",
		"WarningNotice.confirmTitle.send":"Kirim",
		"WarningNotice.confirmContent.send":"Kirim?",
		"WarningNotice.success.send":"Surat Peringatan berhasil",
		"warning_notice.cancel":"Batalkan",
		"WarningNotice.confirmTitle.cancel":"Batalkan",
		"WarningNotice.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Surat Peringatan ini?",
		"WarningNotice.success.cancel":"Surat Peringatan telah berhasil dibatalkan",
		"WarningNotice.name":"Nomor",
		"WarningNotice.employeeId":"Pegawai",
		"WarningNotice.employee":"Pegawai",
		"WarningNotice.date":"Tanggal",
		"WarningNotice.types":"Tipe",
		"WarningNotice.reason":"Alasan",
		"WarningNotice.attachment":"Lampiran",
		"WarningNotice.state":"Status",
		//Payroll Period
		"PayrollPeriod":"Periode Penggajian",
		"payroll_period":"Periode Penggajian",
		"payroll_period.activate":"Aktifkan",
		"PayrollPeriod.confirmTitle.activate":"Aktifkan",
		"PayrollPeriod.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Periode Penggajian ini?",
		"PayrollPeriod.success.activate":"Periode Penggajian telah berhasil diaktifkan",
		"payroll_period.deactivate":"Nonaktifkan",
		"PayrollPeriod.confirmTitle.deactivate":"Nonaktifkan",
		"PayrollPeriod.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Periode Penggajian ini?",
		"PayrollPeriod.success.deactivate":"Periode Penggajian telah berhasil dinonaktifkan",
		"PayrollPeriod.name":"Periode",
		"PayrollPeriod.startDate":"Tanggal",
		"PayrollPeriod.endDate":"Tanggal Akhir",
		"PayrollPeriod.description":"Deskripsi",
		"PayrollPeriod.state":"Status",
		//Shift Period
		"PeriodShift":"Shift Periode",
		"period_shift":"Shift Periode",
		"period_shift.activate":"Aktifkan",
		"PeriodShift.confirmTitle.activate":"Aktifkan",
		"PeriodShift.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Shift Periode ini?",
		"PeriodShift.success.activate":"Shift Periode telah berhasil diaktifkan",
		"period_shift.deactivate":"Nonaktifkan",
		"PeriodShift.confirmTitle.deactivate":"Nonaktifkan",
		"PeriodShift.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Shift Periode ini?",
		"PeriodShift.success.deactivate":"Shift Periode telah berhasil dinonaktifkan",
		"PeriodShift.name":"Nomor",
		"PeriodShift.payrollPeriodId":"Periode",
		"PeriodShift.payrollPeriod":"Periode",
		"PeriodShift.shifts":"Shift",
		"PeriodShift.employeeId":"Pegawai",
		"PeriodShift.employee":"Pegawai",
		"PeriodShift.state":"Status",
		"PeriodShift.locationPeriodShiftId":"Shift",
		"PeriodShift.locationPeriodShift":"Shift",
		//Branch Shift Period
		"BranchPeriodShift":"Shift Periode Per Cabang",
		"branch_period_shift":"Shift Periode Per Cabang",
		"branch_period_shift.confirm":"Konfirmasi",
		"BranchPeriodShift.confirmTitle.confirm":"Konfirmasi",
		"BranchPeriodShift.confirmContent.confirm":"Apakah Anda yakin ingin mengkonfirmasi Shift Periode Per Cabang ini?",
		"BranchPeriodShift.success.confirm":"Shift Periode Per Cabang telah berhasil dikonfirmasi",
		"branch_period_shift.open":"Buka",
		"BranchPeriodShift.confirmTitle.open":"Buka",
		"BranchPeriodShift.confirmContent.open":"Buka?",
		"BranchPeriodShift.success.open":"Shift Periode Per Cabang berhasil",
		"BranchPeriodShift.name":"Nomor",
		"BranchPeriodShift.payrollPeriodId":"Periode",
		"BranchPeriodShift.payrollPeriod":"Periode",
		"BranchPeriodShift.branchId":"Cabang",
		"BranchPeriodShift.branch":"Cabang",
		"BranchPeriodShift.representativeId":"Representasi",
		"BranchPeriodShift.representative":"Representasi",
		"BranchPeriodShift.shifts":"Pegawai",
		"BranchPeriodShift.state":"Status",
		//Shift
		"Shift":"Shift",
		"shift":"Shift",
		"Shift.name":"Nomor",
		"Shift.payrollPeriodId":"Period",
		"Shift.payrollPeriod":"Period",
		"Shift.startTime":"Shift Mulai",
		"Shift.endTime":"Shift Selesai",
		"Shift.attendances":"Kehadiran",
		"Shift.isAttended":"Hadir",
		"Shift.isLeave":"Cuti",
		"Shift.isAbsent":"Tidak Hadir",
		"Shift.isAlpha":"Alpa",
		"Shift.isLate":"Terlambat",
		"Shift.isEarly":"Lebih Awal",
		"Shift.isHoliday":"Libur",
		"Shift.penalized":"Kena Penalti",
		"Shift.periodShiftId":"Periode Shift",
		"Shift.periodShift":"Periode Shift",
		"Shift.employeeId":"Pegawai",
		"Shift.employee":"Pegawai",
		"Shift.state":"Status",
		//Shift Attendance
		"ShiftAttendance":"Kehadiran Shift",
		"shift_attendance":"Kehadiran Shift",
		"ShiftAttendance.shiftId":"Shift",
		"ShiftAttendance.shift":"Shift",
		"ShiftAttendance.time":"Waktu",
		"ShiftAttendance.logId":"Log",
		"ShiftAttendance.log":"Log",
		"ShiftAttendance.state":"Status",
		//Shift Template
		"ShiftTemplate":"Template Shift",
		"shift_template":"Template Shift",
		"shift_template.activate":"Aktifkan",
		"ShiftTemplate.confirmTitle.activate":"Aktifkan",
		"ShiftTemplate.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Template Shift ini?",
		"ShiftTemplate.success.activate":"Template Shift telah berhasil diaktifkan",
		"shift_template.deactivate":"Nonaktifkan",
		"ShiftTemplate.confirmTitle.deactivate":"Nonaktifkan",
		"ShiftTemplate.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Template Shift ini?",
		"ShiftTemplate.success.deactivate":"Template Shift telah berhasil dinonaktifkan",
		"ShiftTemplate.name":"Nomor",
		"ShiftTemplate.shifts":"Daftar",
		"ShiftTemplate.employerId":"Pemberi Upah",
		"ShiftTemplate.employer":"Pemberi Upah",
		"ShiftTemplate.state":"Status",
		//Shift Template List
		"ShiftTemplateLine":"Daftar Shift Template",
		"shift_template_line":"Daftar Shift Template",
		"ShiftTemplateLine.templateId":"Template Shift",
		"ShiftTemplateLine.template":"Template Shift",
		"ShiftTemplateLine.startDay":"Hari Mulai",
		"ShiftTemplateLine.startHour":"Jam",
		"ShiftTemplateLine.endDay":"Hari Selesai",
		"ShiftTemplateLine.endHour":"Jam",
		//Leave Quota
		"LeaveQuota":"Kuota Cuti",
		"leave_quota":"Kuota Cuti",
		"leave_quota.activate":"Aktifkan",
		"LeaveQuota.confirmTitle.activate":"Aktifkan",
		"LeaveQuota.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Kuota Cuti ini?",
		"LeaveQuota.success.activate":"Kuota Cuti telah berhasil diaktifkan",
		"leave_quota.deactivate":"Nonaktifkan",
		"LeaveQuota.confirmTitle.deactivate":"Nonaktifkan",
		"LeaveQuota.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Kuota Cuti ini?",
		"LeaveQuota.success.deactivate":"Kuota Cuti telah berhasil dinonaktifkan",
		"LeaveQuota.name":"Nomor",
		"LeaveQuota.date":"Tanggal Dibuat",
		"LeaveQuota.expiryDate":"Tanggal Kadaluarsa",
		"LeaveQuota.balance":"Saldo",
		"LeaveQuota.leaveLines":"Pemakaian Cuti",
		"LeaveQuota.state":"Status",
		//Leave
		"Leave":"Cuti",
		"leave":"Cuti",
		"leave.request":"Ajukan",
		"Leave.confirmTitle.request":"Ajukan",
		"Leave.confirmContent.request":"Ajukan?",
		"Leave.success.request":"Cuti berhasil",
		"leave.confirm":"Konfirmasi",
		"Leave.confirmTitle.confirm":"Konfirmasi",
		"Leave.confirmContent.confirm":"Apakah Anda yakin ingin mengkonfirmasi Cuti ini?",
		"Leave.success.confirm":"Cuti telah berhasil dikonfirmasi",
		"leave.cancel":"Batalkan",
		"Leave.confirmTitle.cancel":"Batalkan",
		"Leave.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Cuti ini?",
		"Leave.success.cancel":"Cuti telah berhasil dibatalkan",
		"leave.reject":"Tolak",
		"Leave.confirmTitle.reject":"Tolak",
		"Leave.confirmContent.reject":"Tolak?",
		"Leave.success.reject":"Cuti berhasil",
		"Leave.name":"Nomor",
		"Leave.reason":"Deskripsi",
		"Leave.attachment":"Lampiran",
		"Leave.date":"Tanggal",
		"Leave.lines":"Pemakaian Kuota Cuti",
		"Leave.state":"Status",
		//Leave Quota Usage
		"LeaveLine":"Pemakaian Cuti",
		"leave_line":"Pemakaian Cuti",
		"LeaveLine.shiftId":"Shift",
		"LeaveLine.shift":"Shift",
		"LeaveLine.leaveId":"Cuti",
		"LeaveLine.leave":"Cuti",
		"LeaveLine.leaveQuotaId":"Kuota Cuti",
		"LeaveLine.leaveQuota":"Kuota Cuti",
		"LeaveLine.timeStart":"Waktu Mulai",
		"LeaveLine.timeEnd":"Waktu Selesai",
		//Rolling Off
		"RollingOff":"Rolling Off",
		"rolling_off":"Rolling Off",
		"rolling_off.request":"Ajukan",
		"RollingOff.confirmTitle.request":"Ajukan",
		"RollingOff.confirmContent.request":"Ajukan?",
		"RollingOff.success.request":"Rolling Off berhasil",
		"rolling_off.confirm":"Konfirmasi",
		"RollingOff.confirmTitle.confirm":"Konfirmasi",
		"RollingOff.confirmContent.confirm":"Apakah Anda yakin ingin mengkonfirmasi Rolling Off ini?",
		"RollingOff.success.confirm":"Rolling Off telah berhasil dikonfirmasi",
		"rolling_off.cancel":"Batalkan",
		"RollingOff.confirmTitle.cancel":"Batalkan",
		"RollingOff.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Rolling Off ini?",
		"RollingOff.success.cancel":"Rolling Off telah berhasil dibatalkan",
		"rolling_off.reject":"Tolak",
		"RollingOff.confirmTitle.reject":"Tolak",
		"RollingOff.confirmContent.reject":"Tolak?",
		"RollingOff.success.reject":"Rolling Off berhasil",
		"RollingOff.joinDate":"Tanggal Bergabung",
		"RollingOff.name":"Nomor",
		"RollingOff.leaveQuotaId":"Kuota Cuti",
		"RollingOff.leaveQuota":"Kuota Cuti",
		"RollingOff.state":"Status",
		//Off Days
		"OffDays":"Hari Libur",
		"off_days":"Hari Libur",
		"off_days.confirm":"Konfirmasi",
		"OffDays.confirmTitle.confirm":"Konfirmasi",
		"OffDays.confirmContent.confirm":"Apakah Anda yakin ingin mengkonfirmasi Hari Libur ini?",
		"OffDays.success.confirm":"Hari Libur telah berhasil dikonfirmasi",
		"off_days.cancel":"Batalkan",
		"OffDays.confirmTitle.cancel":"Batalkan",
		"OffDays.confirmContent.cancel":"Apakah Anda yakin ingin membatalkan Hari Libur ini?",
		"OffDays.success.cancel":"Hari Libur telah berhasil dibatalkan",
		"off_days.draft":"Draf",
		"OffDays.confirmTitle.draft":"Draf",
		"OffDays.confirmContent.draft":"Draf?",
		"OffDays.success.draft":"Hari Libur berhasil",
		"OffDays.name":"Nomor",
		"OffDays.branchId":"Cabang",
		"OffDays.branch":"Cabang",
		"OffDays.allBranch":"Semua Cabang",
		"OffDays.days":"Hari Libur",
		"OffDays.state":"Status",
		//Off Days Day
		"OffDaysDay":"Hari Libur Hari",
		"off_days_day":"Hari Libur Hari",
		"OffDaysDay.joinDate":"Tanggal Bergabung",
		"OffDaysDay.expendLeave":"Potong Cuti",
		"OffDaysDay.description":"Deskripsi",
		"OffDaysDay.offDaysId":"Hari Libur",
		"OffDaysDay.offDays":"Hari Libur",
		"OffDaysDay.branchId":"Cabang",
		"OffDaysDay.branch":"Cabang",
		"OffDaysDay.allBranch":"Semua Cabang",
		"OffDaysDay.state":"Status",
		//Attendance
		"AttendanceLogLine":"Kehadiran",
		"attendance_log_line":"Kehadiran",
		"attendance_log_line.activate":"Aktifkan",
		"AttendanceLogLine.confirmTitle.activate":"Aktifkan",
		"AttendanceLogLine.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Kehadiran ini?",
		"AttendanceLogLine.success.activate":"Kehadiran telah berhasil diaktifkan",
		"attendance_log_line.deactivate":"Nonaktifkan",
		"AttendanceLogLine.confirmTitle.deactivate":"Nonaktifkan",
		"AttendanceLogLine.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Kehadiran ini?",
		"AttendanceLogLine.success.deactivate":"Kehadiran telah berhasil dinonaktifkan",
		"AttendanceLogLine.employeeID":"Pegawai",
		"AttendanceLogLine.employeeNonpayrollID":"Pegawai",
		"AttendanceLogLine.dateTime":"Waktu",
		"AttendanceLogLine.client":"Lokasi",
		"AttendanceLogLine.state":"Status",
		//Attendance Data Request
		"CommandFetchLog":"Tarik Data Kehadiran",
		"command_fetch_log":"Tarik Data Kehadiran",
		"command_fetch_log.request":"Tarik",
		"CommandFetchLog.confirmTitle.request":"Tarik",
		"CommandFetchLog.confirmContent.request":"Tarik?",
		"CommandFetchLog.success.request":"Tarik Data Kehadiran berhasil",
		"command_fetch_log.draft":"Draf",
		"CommandFetchLog.confirmTitle.draft":"Draf",
		"CommandFetchLog.confirmContent.draft":"Draf?",
		"CommandFetchLog.success.draft":"Tarik Data Kehadiran berhasil",
		"CommandFetchLog.clientID":"Lokasi",
		"CommandFetchLog.state":"Status",
		//Employee
		"EmployeeF":"Karyawan",
		"employee_f":"Karyawan",
		"employee_f.activate":"Aktifkan",
		"EmployeeF.confirmTitle.activate":"Aktifkan",
		"EmployeeF.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Karyawan ini?",
		"EmployeeF.success.activate":"Karyawan telah berhasil diaktifkan",
		"employee_f.deactivate":"Nonaktifkan",
		"EmployeeF.confirmTitle.deactivate":"Nonaktifkan",
		"EmployeeF.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Karyawan ini?",
		"EmployeeF.success.deactivate":"Karyawan telah berhasil dinonaktifkan",
		"EmployeeF.name":"Nomor",
		"EmployeeF.name":"Nama",
		"EmployeeF.biometrics":"Data",
		"EmployeeF.state":"Status",
		//Employee Non Payroll
		"EmployeeNonPayroll":"Karyawan Non Payroll",
		"employee_non_payroll":"Karyawan Non Payroll",
		"employee_non_payroll.activate":"Aktifkan",
		"EmployeeNonPayroll.confirmTitle.activate":"Aktifkan",
		"EmployeeNonPayroll.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Karyawan Non Payroll ini?",
		"EmployeeNonPayroll.success.activate":"Karyawan Non Payroll telah berhasil diaktifkan",
		"employee_non_payroll.deactivate":"Nonaktifkan",
		"EmployeeNonPayroll.confirmTitle.deactivate":"Nonaktifkan",
		"EmployeeNonPayroll.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Karyawan Non Payroll ini?",
		"EmployeeNonPayroll.success.deactivate":"Karyawan Non Payroll telah berhasil dinonaktifkan",
		"EmployeeNonPayroll.no":"Nama",
		"EmployeeNonPayroll.partnerName":"Nama",
		"EmployeeNonPayroll.clientID":"Lokasi",
		"EmployeeNonPayroll.state":"Status",
		//Biometric
		"Biometric":"Biometrik",
		"biometric":"Biometrik",
		"biometric.activate":"Aktifkan",
		"Biometric.confirmTitle.activate":"Aktifkan",
		"Biometric.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Biometrik ini?",
		"Biometric.success.activate":"Biometrik telah berhasil diaktifkan",
		"biometric.deactivate":"Nonaktifkan",
		"Biometric.confirmTitle.deactivate":"Nonaktifkan",
		"Biometric.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Biometrik ini?",
		"Biometric.success.deactivate":"Biometrik telah berhasil dinonaktifkan",
		"Biometric.employee_id":"Pegawai",
		"Biometric.employee_nonpayroll_id":"Pegawai",
		"Biometric.fingerID":"ID",
		"Biometric.size":"Ukuran",
		"Biometric.valid":"Berlaku",
		"Biometric.state":"Status",
		//Fingerprint Location
		"Client":"Lokasi Fingerprint",
		"client":"Lokasi Fingerprint",
		"client.activate":"Aktifkan",
		"Client.confirmTitle.activate":"Aktifkan",
		"Client.confirmContent.activate":"Apakah Anda yakin ingin mengaktifkan Lokasi Fingerprint ini?",
		"Client.success.activate":"Lokasi Fingerprint telah berhasil diaktifkan",
		"client.deactivate":"Nonaktifkan",
		"Client.confirmTitle.deactivate":"Nonaktifkan",
		"Client.confirmContent.deactivate":"Apakah Anda yakin ingin menonaktifkan Lokasi Fingerprint ini?",
		"Client.success.deactivate":"Lokasi Fingerprint telah berhasil dinonaktifkan",
		"Client.name":"Nama",
		"Client.token":"Token",
		"Client.role":"Tipe",
		"Client.lastAccess":"Akses",
		"Client.state":"Status",
		//MENUS
		"Human Capital":"SDM",
		"Employee":"Pegawai",
		"HumanCapitalEmployee":"Pegawai",
		"Contract":"Kontrak",
		"HumanCapitalContract":"Kontrak",
		"Warning Notice":"Surat Peringatan",
		"HumanCapitalWarningNotice":"Surat Peringatan",
		"Payroll Period":"Periode Penggajian",
		"HumanCapitalPayrollPeriod":"Periode Penggajian",
		"Leave Quota":"Kuota Cuti",
		"HumanCapitalLeaveQuota":"Kuota Cuti",
		"Off Days":"Hari Libur",
		"HumanCapitalOffDays":"Hari Libur",
		"Human Capital Report":"Laporan SDM",
		"Shift":"Shift",
		"HumanCapitalReportShift":"Shift",
		"Attendance":"Absensi",
		"HumanCapitalReportAttendance":"Absensi",
		"Employment Statistic":"Statistik Kepegawaian",
		"HumanCapitalReportEmploymentStatistic":"Statistik Kepegawaian",
		"Human Capital Configuration":"Konfigurasi SDM",
		"Employer":"Pemberi Upah",
		"HumanCapitalConfigurationEmployer":"Pemberi Upah",
		"Branch":"Cabang",
		"HumanCapitalConfigurationBranch":"Cabang",
		"Department":"Departemen",
		"HumanCapitalConfigurationDepartment":"Departemen",
		"Section":"Bagian",
		"HumanCapitalConfigurationSection":"Bagian",
		"Position":"Posisi",
		"HumanCapitalConfigurationPosition":"Posisi",
		"Level":"Level",
		"HumanCapitalConfigurationLevel":"Level",
		"Religion":"Agama",
		"HumanCapitalConfigurationReligion":"Agama",
		"Representative":"Representasi",
		"Monthly Shift":"Shift Bulanan",
		"RepresentativeMonthlyShift":"Shift Bulanan",
		"Employee Shift":"Shift Karyawan",
		"RepresentativeEmployeeShift":"Shift Karyawan",
		"Non Payroll Employee":"Karyawan Non Payroll",
		"RepresentativeNonPayrollEmployee":"Karyawan Non Payroll",
		"Fetch Attendance":"Tarik Data Kehadiran",
		"RepresentativeFetchAttendance":"Tarik Data Kehadiran",
		"Shift Template":"Template Shift",
		"RepresentativeShiftTemplate":"Template Shift",
		"Representative Report":"Laporan Representasi",
		"Shift":"Shift",
		"RepresentativeReportShift":"Shift",
		"Attendance":"Absensi",
		"RepresentativeReportAttendance":"Absensi",
		"Employment":"Kepegawaian",
		"Leave":"Ijin / Cuti",
		"EmploymentLeave":"Ijin / Cuti",
		"Rolling Off":"Rolling Off",
		"EmploymentRollingOff":"Rolling Off",
		"Employment Report":"Laporan Kepegawaian",
		"Shift":"Shift",
		"EmploymentReportShift":"Shift",
		"Attendance":"Absensi",
		"EmploymentReportAttendance":"Absensi",
		"Fingerprint Configuration":"Konfigurasi Fingerprint",
		"Employee":"Pegawai",
		"FingerprintConfigurationEmployee":"Pegawai",
		"Non Payroll Employee":"Rolling Off",
		"FingerprintConfigurationNonPayrollEmployee":"Rolling Off",
		"Biometric":"Biometrik",
		"FingerprintConfigurationBiometric":"Biometrik",
		"Client":"Klien",
		"FingerprintConfigurationClient":"Klien",
		//OPTIONS
		"active":"Aktif",
		"inactive":"Nonaktif",
		"candidate":"candidate",
		"male":"male",
		"female":"female",
		"single":"single",
		"married":"married",
		"option1":"option1",
		"option2":"option2",
		"option3":"option3",
		"contract":"contract",
		"permanent":"permanent",
		"draft":"Draf",
		"confirmed":"Dikonfirmasi",
		"first":"first",
		"second":"second",
		"third":"third",
		"open":"open",
		"close":"close",
		"complete":"Selesai",
		"cancelled":"Dibatalkan",
		"waiting":"Menunggu",
		"finished":"Selesai",
		"client":"Klien",
		"master":"Master",

		"General Information": "Informasi Umum",
		"Personal Information": "Informasi Pribadi",
		"Work Schedule": "Jadwal Kerja",
		"Payroll": "Penggajian",
		"Tax Information": "Informasi Pajak",
		"BPJS Information": "Informasi BPJS",
		"System": "Sistem",
	},
	en:{
		"hr":"hr",
		//Employee
		"Employee":"Employee",
		"employee":"Employee",
		"employee.contract":"Create Contract",
		"Employee.confirmTitle.contract":"Create Contract",
		"Employee.confirmContent.contract":"Create Contract?",
		"Employee.success.contract":"Employee is successful",
		"employee.warn":"Warning Letter",
		"Employee.confirmTitle.warn":"Warning Letter",
		"Employee.confirmContent.warn":"Warning Letter?",
		"Employee.success.warn":"Employee is successful",
		"employee.terminate":"Terminate",
		"Employee.confirmTitle.terminate":"Terminate",
		"Employee.confirmContent.terminate":"Terminate?",
		"Employee.success.terminate":"Employee is successful",
		"Employee.number":"Number",
		"Employee.name":"Name",
		"Employee.managerId":"Manager",
		"Employee.manager":"Manager",
		"Employee.indirectManagerId":"Indirect Manager",
		"Employee.indirectManager":"Indirect Manager",
		"Employee.employerId":"Employer",
		"Employee.employer":"Employer",
		"Employee.branchId":"Branch",
		"Employee.branch":"Branch",
		"Employee.departmentId":"Department",
		"Employee.department":"Department",
		"Employee.sectionId":"Section",
		"Employee.section":"Section",
		"Employee.positionId":"Position",
		"Employee.position":"Position",
		"Employee.levelId":"Level",
		"Employee.level":"Level",
		"Employee.joinDate":"Hiring Date",
		"Employee.joinAge":"Tenure",
		"Employee.contractId":"Contract",
		"Employee.contract":"Contract",
		"Employee.activeDays":"Days Left",
		"Employee.placeOfBirth":"Place of Birth",
		"Employee.dateOfBirth":"Date of Birth",
		"Employee.currentAge":"Age",
		"Employee.sex":"Sex",
		"Employee.bloodType":"Blood Type",
		"Employee.ktp":"National ID",
		"Employee.ktpExpiryDate":"National ID Expiry Date",
		"Employee.ktpAttachment":"National ID Attachment",
		"Employee.address":"Address",
		"Employee.ktpAddress":"National ID Address",
		"Employee.city":"City",
		"Employee.office":"Office Address",
		"Employee.phone":"Phone",
		"Employee.mobile":"Mobile",
		"Employee.email":"Email",
		"Employee.experiences":"Experiences",
		"Employee.educations":"Education",
		"Employee.religionId":"Religion",
		"Employee.religion":"Religion",
		"Employee.mother":"Mother",
		"Employee.sibling":"Sibling",
		"Employee.maritalStatus":"Marital Status",
		"Employee.spouse":"Spouse",
		"Employee.spouseDateOfBirth":"Spouse Date of Birth",
		"Employee.spouseAge":"Spouse Age",
		"Employee.childrenCount":"Number of Child",
		"Employee.children":"Children",
		"Employee.types":"Attendance Type",
		"Employee.shiftTemplateId":"Shift Template",
		"Employee.shiftTemplate":"Shift Template",
		"Employee.leaveBalance":"Leave Balance",
		"Employee.leaveQuotas":"Leave Quota",
		"Employee.leaves":"Daftar Cuti / Ijin",
		"Employee.rollingOffs":"Rolling Off",
		"Employee.payrollCode":"Payroll Code",
		"Employee.bankAccount":"Akun Bank",
		"Employee.salary":"Salary",
		"Employee.npwp":"NPWP",
		"Employee.npwpAddress":"NPWP Address",
		"Employee.taxMother":"Mother (Tax)",
		"Employee.taxSibling":"Sibling (Tax)",
		"Employee.taxSpouse":"Spouse (Tax)",
		"Employee.taxSpouseDateOfBirth":"Spouse Date of Birth (Tax)",
		"Employee.taxSpouseAge":"Spouse Age (Tax)",
		"Employee.taxChildrenCount":"Number of Child (Tax)",
		"Employee.taxChildren":"Children (Tax)",
		"Employee.kartuKeluargaNumber":"Family Card Number",
		"Employee.kartuKeluargaAttachment":"Family Card Scan",
		"Employee.kartuKpjBpjstkNumber":"BPJS Ketenagakerjaan",
		"Employee.kartuBpjsKesehatanNumber":"BPJS Kesehatan",
		"Employee.contracts":"Contract History",
		"Employee.employers":"Employer History",
		"Employee.branches":"Branch History",
		"Employee.departments":"Department History",
		"Employee.positions":"Position History",
		"Employee.sections":"Section History",
		"Employee.levels":"Level History",
		"Employee.userId":"User Account",
		"Employee.user":"User Account",
		"Employee.fingerprintId":"Fingerprint",
		"Employee.fingerprint":"Fingerprint",
		"Employee.state":"State",
		"Employee.proposeDate":"Propose Date",
		"Employee.notices":"Notices",
		"Employee.griefDescription":"Grief Description",
		"Employee.unbound":"Unbounded",
		"Employee.notes":"Note",
		//Religion
		"Religion":"Religion",
		"religion":"Religion",
		"religion.activate":"Activate",
		"Religion.confirmTitle.activate":"Activate",
		"Religion.confirmContent.activate":"Are you sure you want to activate this Religion?",
		"Religion.success.activate":"Religion is successfully activated",
		"religion.deactivate":"Deactivate",
		"Religion.confirmTitle.deactivate":"Deactivate",
		"Religion.confirmContent.deactivate":"Are you sure you want to deactivate this Religion?",
		"Religion.success.deactivate":"Religion is successfully deactivated",
		"Religion.name":"Name",
		"Religion.state":"State",
		//Contract
		"Contract":"Contract",
		"contract":"Contract",
		"contract.activate":"Activate",
		"Contract.confirmTitle.activate":"Activate",
		"Contract.confirmContent.activate":"Are you sure you want to activate this Contract?",
		"Contract.success.activate":"Contract is successfully activated",
		"contract.deactivate":"Deactivate",
		"Contract.confirmTitle.deactivate":"Deactivate",
		"Contract.confirmContent.deactivate":"Are you sure you want to deactivate this Contract?",
		"Contract.success.deactivate":"Contract is successfully deactivated",
		"Contract.name":"Number",
		"Contract.contractType":"Contract Type",
		"Contract.dateStart":"Date Start",
		"Contract.dateEnd":"Date End",
		"Contract.current":"Active",
		"Contract.activeDays":"Tenure (days)",
		"Contract.notifyContract":"Notification",
		"Contract.description":"Description",
		"Contract.employeeId":"Employee",
		"Contract.employee":"Employee",
		"Contract.state":"Status",
		//Branch
		"Branch":"Branch",
		"branch":"Branch",
		"branch.activate":"Activate",
		"Branch.confirmTitle.activate":"Activate",
		"Branch.confirmContent.activate":"Are you sure you want to activate this Branch?",
		"Branch.success.activate":"Branch is successfully activated",
		"branch.deactivate":"Deactivate",
		"Branch.confirmTitle.deactivate":"Deactivate",
		"Branch.confirmContent.deactivate":"Are you sure you want to deactivate this Branch?",
		"Branch.success.deactivate":"Branch is successfully deactivated",
		"Branch.name":"Name",
		"Branch.representativeId":"Representative",
		"Branch.representative":"Representative",
		"Branch.clientId":"Fingerprint Client",
		"Branch.client":"Fingerprint Client",
		"Branch.lateTolerance":"Late Tolerance",
		"Branch.earlyTolerance":"Early Leave Tolerance",
		"Branch.state":"Status",
		//Employee Branch
		"EmployeeBranch":"Employee Branch",
		"employee_branch":"Employee Branch",
		"employee_branch.activate":"Activate",
		"EmployeeBranch.confirmTitle.activate":"Activate",
		"EmployeeBranch.confirmContent.activate":"Are you sure you want to activate this Employee Branch?",
		"EmployeeBranch.success.activate":"Employee Branch is successfully activated",
		"employee_branch.deactivate":"Deactivate",
		"EmployeeBranch.confirmTitle.deactivate":"Deactivate",
		"EmployeeBranch.confirmContent.deactivate":"Are you sure you want to deactivate this Employee Branch?",
		"EmployeeBranch.success.deactivate":"Employee Branch is successfully deactivated",
		"EmployeeBranch.dateStart":"Start Date",
		"EmployeeBranch.dateEnd":"End Date",
		"EmployeeBranch.current":"Active",
		"EmployeeBranch.description":"Description",
		"EmployeeBranch.branchId":"Branch",
		"EmployeeBranch.branch":"Branch",
		"EmployeeBranch.employeeId":"Employee",
		"EmployeeBranch.employee":"Employee",
		"EmployeeBranch.state":"Status",
		//Employer
		"Employer":"Employer",
		"employer":"Employer",
		"employer.activate":"Activate",
		"Employer.confirmTitle.activate":"Activate",
		"Employer.confirmContent.activate":"Are you sure you want to activate this Employer?",
		"Employer.success.activate":"Employer is successfully activated",
		"employer.deactivate":"Deactivate",
		"Employer.confirmTitle.deactivate":"Deactivate",
		"Employer.confirmContent.deactivate":"Are you sure you want to deactivate this Employer?",
		"Employer.success.deactivate":"Employer is successfully deactivated",
		"Employer.name":"Name",
		//Employee Employer
		"EmployeeEmployer":"Employee Employer",
		"employee_employer":"Employee Employer",
		"EmployeeEmployer.dateStart":"Start",
		"EmployeeEmployer.dateEnd":"End Date",
		"EmployeeEmployer.current":"Active",
		"EmployeeEmployer.description":"Description",
		"EmployeeEmployer.employerId":"Employer",
		"EmployeeEmployer.employer":"Employer",
		"EmployeeEmployer.employeeId":"Employee",
		"EmployeeEmployer.employee":"Employee",
		"EmployeeEmployer.state":"Status",
		//Level
		"Level":"Level",
		"level":"Level",
		"level.activate":"Activate",
		"Level.confirmTitle.activate":"Activate",
		"Level.confirmContent.activate":"Are you sure you want to activate this Level?",
		"Level.success.activate":"Level is successfully activated",
		"level.deactivate":"Deactivate",
		"Level.confirmTitle.deactivate":"Deactivate",
		"Level.confirmContent.deactivate":"Are you sure you want to deactivate this Level?",
		"Level.success.deactivate":"Level is successfully deactivated",
		"Level.name":"Name",
		"Level.state":"Status",
		//Employee Level
		"EmployeeLevel":"Employee Level",
		"employee_level":"Employee Level",
		"EmployeeLevel.dateStart":"Start",
		"EmployeeLevel.dateEnd":"End Date",
		"EmployeeLevel.current":"Active",
		"EmployeeLevel.description":"Description",
		"EmployeeLevel.levelId":"Level",
		"EmployeeLevel.level":"Level",
		"EmployeeLevel.employeeId":"Employee",
		"EmployeeLevel.employee":"Employee",
		"EmployeeLevel.state":"Status",
		//Position
		"Position":"Position",
		"position":"Position",
		"position.activate":"Activate",
		"Position.confirmTitle.activate":"Activate",
		"Position.confirmContent.activate":"Are you sure you want to activate this Position?",
		"Position.success.activate":"Position is successfully activated",
		"position.deactivate":"Deactivate",
		"Position.confirmTitle.deactivate":"Deactivate",
		"Position.confirmContent.deactivate":"Are you sure you want to deactivate this Position?",
		"Position.success.deactivate":"Position is successfully deactivated",
		"Position.name":"Name",
		"Position.state":"Status",
		//Employee Position
		"EmployeePosition":"Employee Position",
		"employee_position":"Employee Position",
		"EmployeePosition.dateStart":"Start",
		"EmployeePosition.dateEnd":"End Date",
		"EmployeePosition.current":"Active",
		"EmployeePosition.description":"Description",
		"EmployeePosition.positionId":"Position",
		"EmployeePosition.position":"Position",
		"EmployeePosition.employeeId":"Employee",
		"EmployeePosition.employee":"Employee",
		"EmployeePosition.state":"Status",
		//Department
		"Department":"Department",
		"department":"Department",
		"department.activate":"Activate",
		"Department.confirmTitle.activate":"Activate",
		"Department.confirmContent.activate":"Are you sure you want to activate this Department?",
		"Department.success.activate":"Department is successfully activated",
		"department.deactivate":"Deactivate",
		"Department.confirmTitle.deactivate":"Deactivate",
		"Department.confirmContent.deactivate":"Are you sure you want to deactivate this Department?",
		"Department.success.deactivate":"Department is successfully deactivated",
		"Department.name":"Name",
		"Department.state":"Status",
		//Employee Department
		"EmployeeDepartment":"Employee Department",
		"employee_department":"Employee Department",
		"EmployeeDepartment.dateStart":"Start",
		"EmployeeDepartment.dateEnd":"End Date",
		"EmployeeDepartment.current":"Active",
		"EmployeeDepartment.description":"Description",
		"EmployeeDepartment.departmentId":"Department",
		"EmployeeDepartment.department":"Department",
		"EmployeeDepartment.employeeId":"Employee",
		"EmployeeDepartment.employee":"Employee",
		"EmployeeDepartment.state":"Status",
		//Section
		"Section":"Section",
		"section":"Section",
		"section.activate":"Activate",
		"Section.confirmTitle.activate":"Activate",
		"Section.confirmContent.activate":"Are you sure you want to activate this Section?",
		"Section.success.activate":"Section is successfully activated",
		"section.deactivate":"Deactivate",
		"Section.confirmTitle.deactivate":"Deactivate",
		"Section.confirmContent.deactivate":"Are you sure you want to deactivate this Section?",
		"Section.success.deactivate":"Section is successfully deactivated",
		"Section.name":"Name",
		"Section.state":"Status",
		//Employee Section
		"EmployeeSection":"Employee Section",
		"employee_section":"Employee Section",
		"EmployeeSection.dateStart":"Start",
		"EmployeeSection.dateEnd":"End Date",
		"EmployeeSection.current":"Active",
		"EmployeeSection.description":"Description",
		"EmployeeSection.sectionId":"Section",
		"EmployeeSection.section":"Section",
		"EmployeeSection.employeeId":"Employee",
		"EmployeeSection.employee":"Employee",
		"EmployeeSection.state":"Status",
		//Employee Child
		"EmployeeChild":"Employee Child",
		"employee_child":"Employee Child",
		"EmployeeChild.name":"Name",
		"EmployeeChild.dateOfBirth":"Date of Birth",
		"EmployeeChild.age":"Age",
		"EmployeeChild.covered":"Covered",
		"EmployeeChild.employeeId":"Employee",
		"EmployeeChild.employee":"Employee",
		//Employee Experience
		"EmployeeExperience":"Employee Experience",
		"employee_experience":"Employee Experience",
		"EmployeeExperience.company":"Company",
		"EmployeeExperience.startPeriod":"Start",
		"EmployeeExperience.endPeriod":"End Date",
		"EmployeeExperience.description":"Description",
		"EmployeeExperience.employeeId":"Employee",
		"EmployeeExperience.employee":"Employee",
		//Employee Education
		"EmployeeEducation":"Employee Education",
		"employee_education":"Employee Education",
		"EmployeeEducation.employeeId":"Employee",
		"EmployeeEducation.employee":"Employee",
		"EmployeeEducation.degree":"Degree",
		"EmployeeEducation.major":"Major",
		"EmployeeEducation.insitution":"Institution",
		//Warning Notice
		"WarningNotice":"Warning Notice",
		"warning_notice":"Warning Notice",
		"warning_notice.send":"Send",
		"WarningNotice.confirmTitle.send":"Send",
		"WarningNotice.confirmContent.send":"Send?",
		"WarningNotice.success.send":"Warning Notice is successful",
		"warning_notice.cancel":"Cancel",
		"WarningNotice.confirmTitle.cancel":"Cancel",
		"WarningNotice.confirmContent.cancel":"Are you sure you want to cancel this Warning Notice?",
		"WarningNotice.success.cancel":"Warning Notice is successfully cancelled",
		"WarningNotice.name":"Number",
		"WarningNotice.employeeId":"Employee",
		"WarningNotice.employee":"Employee",
		"WarningNotice.date":"Date",
		"WarningNotice.types":"Type",
		"WarningNotice.reason":"Reason",
		"WarningNotice.attachment":"Attachment",
		"WarningNotice.state":"Status",
		//Payroll Period
		"PayrollPeriod":"Payroll Period",
		"payroll_period":"Payroll Period",
		"payroll_period.activate":"Activate",
		"PayrollPeriod.confirmTitle.activate":"Activate",
		"PayrollPeriod.confirmContent.activate":"Are you sure you want to activate this Payroll Period?",
		"PayrollPeriod.success.activate":"Payroll Period is successfully activated",
		"payroll_period.deactivate":"Deactivate",
		"PayrollPeriod.confirmTitle.deactivate":"Deactivate",
		"PayrollPeriod.confirmContent.deactivate":"Are you sure you want to deactivate this Payroll Period?",
		"PayrollPeriod.success.deactivate":"Payroll Period is successfully deactivated",
		"PayrollPeriod.name":"Period",
		"PayrollPeriod.startDate":"Start",
		"PayrollPeriod.endDate":"End Date",
		"PayrollPeriod.description":"Description",
		"PayrollPeriod.state":"Status",
		//Shift Period
		"PeriodShift":"Shift Period",
		"period_shift":"Shift Period",
		"period_shift.activate":"Activate",
		"PeriodShift.confirmTitle.activate":"Activate",
		"PeriodShift.confirmContent.activate":"Are you sure you want to activate this Shift Period?",
		"PeriodShift.success.activate":"Shift Period is successfully activated",
		"period_shift.deactivate":"Deactivate",
		"PeriodShift.confirmTitle.deactivate":"Deactivate",
		"PeriodShift.confirmContent.deactivate":"Are you sure you want to deactivate this Shift Period?",
		"PeriodShift.success.deactivate":"Shift Period is successfully deactivated",
		"PeriodShift.name":"Number",
		"PeriodShift.payrollPeriodId":"Period",
		"PeriodShift.payrollPeriod":"Period",
		"PeriodShift.shifts":"Shift",
		"PeriodShift.employeeId":"Employee",
		"PeriodShift.employee":"Employee",
		"PeriodShift.state":"Status",
		"PeriodShift.locationPeriodShiftId":"Branch",
		"PeriodShift.locationPeriodShift":"Branch",
		//Branch Shift Period
		"BranchPeriodShift":"Branch Shift Period",
		"branch_period_shift":"Branch Shift Period",
		"branch_period_shift.confirm":"Confirm",
		"BranchPeriodShift.confirmTitle.confirm":"Confirm",
		"BranchPeriodShift.confirmContent.confirm":"Are you sure you want to confirm this Branch Shift Period?",
		"BranchPeriodShift.success.confirm":"Branch Shift Period is successfully confirmed",
		"branch_period_shift.open":"Open",
		"BranchPeriodShift.confirmTitle.open":"Open",
		"BranchPeriodShift.confirmContent.open":"Open?",
		"BranchPeriodShift.success.open":"Branch Shift Period is successful",
		"BranchPeriodShift.name":"Number",
		"BranchPeriodShift.payrollPeriodId":"Period",
		"BranchPeriodShift.payrollPeriod":"Period",
		"BranchPeriodShift.branchId":"Branch",
		"BranchPeriodShift.branch":"Branch",
		"BranchPeriodShift.representativeId":"Representative",
		"BranchPeriodShift.representative":"Representative",
		"BranchPeriodShift.shifts":"Employees",
		"BranchPeriodShift.state":"Status",
		//Shift
		"Shift":"Shift",
		"shift":"Shift",
		"Shift.name":"Number",
		"Shift.payrollPeriodId":"Periode",
		"Shift.payrollPeriod":"Periode",
		"Shift.startTime":"Shift Start",
		"Shift.endTime":"Shift End",
		"Shift.attendances":"Attendances",
		"Shift.isAttended":"Attended",
		"Shift.isLeave":"Leave",
		"Shift.isAbsent":"Absent",
		"Shift.isAlpha":"Absent Without Notice",
		"Shift.isLate":"Late",
		"Shift.isEarly":"Early",
		"Shift.isHoliday":"Holiday",
		"Shift.penalized":"Penalized",
		"Shift.periodShiftId":"Shift Period",
		"Shift.periodShift":"Shift Period",
		"Shift.employeeId":"Employee",
		"Shift.employee":"Employee",
		"Shift.state":"Status",
		//Shift Attendance
		"ShiftAttendance":"Shift Attendance",
		"shift_attendance":"Shift Attendance",
		"ShiftAttendance.shiftId":"Shift",
		"ShiftAttendance.shift":"Shift",
		"ShiftAttendance.time":"Waktu",
		"ShiftAttendance.logId":"Log",
		"ShiftAttendance.log":"Log",
		"ShiftAttendance.state":"Status",
		//Shift Template
		"ShiftTemplate":"Shift Template",
		"shift_template":"Shift Template",
		"shift_template.activate":"Activate",
		"ShiftTemplate.confirmTitle.activate":"Activate",
		"ShiftTemplate.confirmContent.activate":"Are you sure you want to activate this Shift Template?",
		"ShiftTemplate.success.activate":"Shift Template is successfully activated",
		"shift_template.deactivate":"Deactivate",
		"ShiftTemplate.confirmTitle.deactivate":"Deactivate",
		"ShiftTemplate.confirmContent.deactivate":"Are you sure you want to deactivate this Shift Template?",
		"ShiftTemplate.success.deactivate":"Shift Template is successfully deactivated",
		"ShiftTemplate.name":"Number",
		"ShiftTemplate.shifts":"List",
		"ShiftTemplate.employerId":"Employer",
		"ShiftTemplate.employer":"Employer",
		"ShiftTemplate.state":"Status",
		//Shift Template List
		"ShiftTemplateLine":"Shift Template List",
		"shift_template_line":"Shift Template List",
		"ShiftTemplateLine.templateId":"Shift Template",
		"ShiftTemplateLine.template":"Shift Template",
		"ShiftTemplateLine.startDay":"Start Day",
		"ShiftTemplateLine.startHour":"Start",
		"ShiftTemplateLine.endDay":"End Day",
		"ShiftTemplateLine.endHour":"End",
		//Leave Quota
		"LeaveQuota":"Leave Quota",
		"leave_quota":"Leave Quota",
		"leave_quota.activate":"Activate",
		"LeaveQuota.confirmTitle.activate":"Activate",
		"LeaveQuota.confirmContent.activate":"Are you sure you want to activate this Leave Quota?",
		"LeaveQuota.success.activate":"Leave Quota is successfully activated",
		"leave_quota.deactivate":"Deactivate",
		"LeaveQuota.confirmTitle.deactivate":"Deactivate",
		"LeaveQuota.confirmContent.deactivate":"Are you sure you want to deactivate this Leave Quota?",
		"LeaveQuota.success.deactivate":"Leave Quota is successfully deactivated",
		"LeaveQuota.name":"Number",
		"LeaveQuota.date":"Create Date",
		"LeaveQuota.expiryDate":"Expire Date",
		"LeaveQuota.balance":"Balance",
		"LeaveQuota.leaveLines":"Leaves",
		"LeaveQuota.state":"Status",
		//Leave
		"Leave":"Leave",
		"leave":"Leave",
		"leave.request":"Request",
		"Leave.confirmTitle.request":"Request",
		"Leave.confirmContent.request":"Request?",
		"Leave.success.request":"Leave is successful",
		"leave.confirm":"Confirm",
		"Leave.confirmTitle.confirm":"Confirm",
		"Leave.confirmContent.confirm":"Are you sure you want to confirm this Leave?",
		"Leave.success.confirm":"Leave is successfully confirmed",
		"leave.cancel":"Cancel",
		"Leave.confirmTitle.cancel":"Cancel",
		"Leave.confirmContent.cancel":"Are you sure you want to cancel this Leave?",
		"Leave.success.cancel":"Leave is successfully cancelled",
		"leave.reject":"Reject",
		"Leave.confirmTitle.reject":"Reject",
		"Leave.confirmContent.reject":"Reject?",
		"Leave.success.reject":"Leave is successful",
		"Leave.name":"Number",
		"Leave.reason":"Description",
		"Leave.attachment":"Attachment",
		"Leave.date":"Date",
		"Leave.lines":"Quota Usage",
		"Leave.state":"Status",
		//Leave Quota Usage
		"LeaveLine":"Leave Quota Usage",
		"leave_line":"Leave Quota Usage",
		"LeaveLine.shiftId":"Shift",
		"LeaveLine.shift":"Shift",
		"LeaveLine.leaveId":"Leave",
		"LeaveLine.leave":"Leave",
		"LeaveLine.leaveQuotaId":"Leave Quota",
		"LeaveLine.leaveQuota":"Leave Quota",
		"LeaveLine.timeStart":"Time Start",
		"LeaveLine.timeEnd":"Time End",
		//Rolling Off
		"RollingOff":"Rolling Off",
		"rolling_off":"Rolling Off",
		"rolling_off.request":"Request",
		"RollingOff.confirmTitle.request":"Request",
		"RollingOff.confirmContent.request":"Request?",
		"RollingOff.success.request":"Rolling Off is successful",
		"rolling_off.confirm":"Confirm",
		"RollingOff.confirmTitle.confirm":"Confirm",
		"RollingOff.confirmContent.confirm":"Are you sure you want to confirm this Rolling Off?",
		"RollingOff.success.confirm":"Rolling Off is successfully confirmed",
		"rolling_off.cancel":"Cancel",
		"RollingOff.confirmTitle.cancel":"Cancel",
		"RollingOff.confirmContent.cancel":"Are you sure you want to cancel this Rolling Off?",
		"RollingOff.success.cancel":"Rolling Off is successfully cancelled",
		"rolling_off.reject":"Reject",
		"RollingOff.confirmTitle.reject":"Reject",
		"RollingOff.confirmContent.reject":"Reject?",
		"RollingOff.success.reject":"Rolling Off is successful",
		"RollingOff.joinDate":"Join Date",
		"RollingOff.name":"Number",
		"RollingOff.leaveQuotaId":"Leave Quota",
		"RollingOff.leaveQuota":"Leave Quota",
		"RollingOff.state":"Status",
		//Off Days
		"OffDays":"Off Days",
		"off_days":"Off Days",
		"off_days.confirm":"Confirm",
		"OffDays.confirmTitle.confirm":"Confirm",
		"OffDays.confirmContent.confirm":"Are you sure you want to confirm this Off Days?",
		"OffDays.success.confirm":"Off Days is successfully confirmed",
		"off_days.cancel":"Cancel",
		"OffDays.confirmTitle.cancel":"Cancel",
		"OffDays.confirmContent.cancel":"Are you sure you want to cancel this Off Days?",
		"OffDays.success.cancel":"Off Days is successfully cancelled",
		"off_days.draft":"Draft",
		"OffDays.confirmTitle.draft":"Draft",
		"OffDays.confirmContent.draft":"Draft?",
		"OffDays.success.draft":"Off Days is successful",
		"OffDays.name":"Number",
		"OffDays.branchId":"Branch",
		"OffDays.branch":"Branch",
		"OffDays.allBranch":"All Branch",
		"OffDays.days":"Days",
		"OffDays.state":"Status",
		//Off Days Day
		"OffDaysDay":"Off Days Day",
		"off_days_day":"Off Days Day",
		"OffDaysDay.joinDate":"Join Date",
		"OffDaysDay.expendLeave":"Expend Leave",
		"OffDaysDay.description":"Description",
		"OffDaysDay.offDaysId":"Off Days",
		"OffDaysDay.offDays":"Off Days",
		"OffDaysDay.branchId":"Branch",
		"OffDaysDay.branch":"Branch",
		"OffDaysDay.allBranch":"All Branch",
		"OffDaysDay.state":"Status",
		//Attendance
		"AttendanceLogLine":"Attendance",
		"attendance_log_line":"Attendance",
		"attendance_log_line.activate":"Activate",
		"AttendanceLogLine.confirmTitle.activate":"Activate",
		"AttendanceLogLine.confirmContent.activate":"Are you sure you want to activate this Attendance?",
		"AttendanceLogLine.success.activate":"Attendance is successfully activated",
		"attendance_log_line.deactivate":"Deactivate",
		"AttendanceLogLine.confirmTitle.deactivate":"Deactivate",
		"AttendanceLogLine.confirmContent.deactivate":"Are you sure you want to deactivate this Attendance?",
		"AttendanceLogLine.success.deactivate":"Attendance is successfully deactivated",
		"AttendanceLogLine.employeeID":"Employee",
		"AttendanceLogLine.employeeNonpayrollID":"Non",
		"AttendanceLogLine.dateTime":"Time",
		"AttendanceLogLine.client":"Location",
		"AttendanceLogLine.state":"State",
		//Attendance Data Request
		"CommandFetchLog":"Attendance Data Request",
		"command_fetch_log":"Attendance Data Request",
		"command_fetch_log.request":"Request",
		"CommandFetchLog.confirmTitle.request":"Request",
		"CommandFetchLog.confirmContent.request":"Request?",
		"CommandFetchLog.success.request":"Attendance Data Request is successful",
		"command_fetch_log.draft":"Draft",
		"CommandFetchLog.confirmTitle.draft":"Draft",
		"CommandFetchLog.confirmContent.draft":"Draft?",
		"CommandFetchLog.success.draft":"Attendance Data Request is successful",
		"CommandFetchLog.clientID":"Location",
		"CommandFetchLog.state":"State",
		//Employee
		"EmployeeF":"Employee",
		"employee_f":"Employee",
		"employee_f.activate":"Activate",
		"EmployeeF.confirmTitle.activate":"Activate",
		"EmployeeF.confirmContent.activate":"Are you sure you want to activate this Employee?",
		"EmployeeF.success.activate":"Employee is successfully activated",
		"employee_f.deactivate":"Deactivate",
		"EmployeeF.confirmTitle.deactivate":"Deactivate",
		"EmployeeF.confirmContent.deactivate":"Are you sure you want to deactivate this Employee?",
		"EmployeeF.success.deactivate":"Employee is successfully deactivated",
		"EmployeeF.name":"Number",
		"EmployeeF.name":"Name",
		"EmployeeF.biometrics":"Biometrics",
		"EmployeeF.state":"State",
		//Employee Non Payroll
		"EmployeeNonPayroll":"Employee Non Payroll",
		"employee_non_payroll":"Employee Non Payroll",
		"employee_non_payroll.activate":"Activate",
		"EmployeeNonPayroll.confirmTitle.activate":"Activate",
		"EmployeeNonPayroll.confirmContent.activate":"Are you sure you want to activate this Employee Non Payroll?",
		"EmployeeNonPayroll.success.activate":"Employee Non Payroll is successfully activated",
		"employee_non_payroll.deactivate":"Deactivate",
		"EmployeeNonPayroll.confirmTitle.deactivate":"Deactivate",
		"EmployeeNonPayroll.confirmContent.deactivate":"Are you sure you want to deactivate this Employee Non Payroll?",
		"EmployeeNonPayroll.success.deactivate":"Employee Non Payroll is successfully deactivated",
		"EmployeeNonPayroll.no":"Name",
		"EmployeeNonPayroll.partnerName":"Name",
		"EmployeeNonPayroll.clientID":"Location",
		"EmployeeNonPayroll.state":"State",
		//Biometric
		"Biometric":"Biometric",
		"biometric":"Biometric",
		"biometric.activate":"Activate",
		"Biometric.confirmTitle.activate":"Activate",
		"Biometric.confirmContent.activate":"Are you sure you want to activate this Biometric?",
		"Biometric.success.activate":"Biometric is successfully activated",
		"biometric.deactivate":"Deactivate",
		"Biometric.confirmTitle.deactivate":"Deactivate",
		"Biometric.confirmContent.deactivate":"Are you sure you want to deactivate this Biometric?",
		"Biometric.success.deactivate":"Biometric is successfully deactivated",
		"Biometric.employee_id":"Employee",
		"Biometric.employee_nonpayroll_id":"Non",
		"Biometric.fingerID":"Fingerprint",
		"Biometric.size":"Size",
		"Biometric.valid":"Valid",
		"Biometric.state":"State",
		//Fingerprint Location
		"Client":"Fingerprint Location",
		"client":"Fingerprint Location",
		"client.activate":"Activate",
		"Client.confirmTitle.activate":"Activate",
		"Client.confirmContent.activate":"Are you sure you want to activate this Fingerprint Location?",
		"Client.success.activate":"Fingerprint Location is successfully activated",
		"client.deactivate":"Deactivate",
		"Client.confirmTitle.deactivate":"Deactivate",
		"Client.confirmContent.deactivate":"Are you sure you want to deactivate this Fingerprint Location?",
		"Client.success.deactivate":"Fingerprint Location is successfully deactivated",
		"Client.name":"Name",
		"Client.token":"Token",
		"Client.role":"Type",
		"Client.lastAccess":"Last",
		"Client.state":"State",
		//MENUS
		"Human Capital":"Human Capital",
		"Employee":"Employee",
		"HumanCapitalEmployee":"Employee",
		"Contract":"Contract",
		"HumanCapitalContract":"Contract",
		"Warning Notice":"Warning Notice",
		"HumanCapitalWarningNotice":"Warning Notice",
		"Payroll Period":"Payroll Period",
		"HumanCapitalPayrollPeriod":"Payroll Period",
		"Leave Quota":"Leave Quota",
		"HumanCapitalLeaveQuota":"Leave Quota",
		"Off Days":"Off Days",
		"HumanCapitalOffDays":"Off Days",
		"Human Capital Report":"Human Capital Report",
		"Shift":"Shift",
		"HumanCapitalReportShift":"Shift",
		"Attendance":"Attendance",
		"HumanCapitalReportAttendance":"Attendance",
		"Employment Statistic":"Employment Statistic",
		"HumanCapitalReportEmploymentStatistic":"Employment Statistic",
		"Human Capital Configuration":"Human Capital Configuration",
		"Employer":"Employer",
		"HumanCapitalConfigurationEmployer":"Employer",
		"Branch":"Branch",
		"HumanCapitalConfigurationBranch":"Branch",
		"Department":"Department",
		"HumanCapitalConfigurationDepartment":"Department",
		"Section":"Section",
		"HumanCapitalConfigurationSection":"Section",
		"Position":"Position",
		"HumanCapitalConfigurationPosition":"Position",
		"Level":"Level",
		"HumanCapitalConfigurationLevel":"Level",
		"Religion":"Religion",
		"HumanCapitalConfigurationReligion":"Religion",
		"Representative":"Representative",
		"Monthly Shift":"Monthly Shift",
		"RepresentativeMonthlyShift":"Monthly Shift",
		"Employee Shift":"Employee Shift",
		"RepresentativeEmployeeShift":"Employee Shift",
		"Non Payroll Employee":"Non Payroll Employee",
		"RepresentativeNonPayrollEmployee":"Non Payroll Employee",
		"Fetch Attendance":"Fetch Attendance",
		"RepresentativeFetchAttendance":"Fetch Attendance",
		"Shift Template":"Shift Template",
		"RepresentativeShiftTemplate":"Shift Template",
		"Representative Report":"Representative Report",
		"Shift":"Shift",
		"RepresentativeReportShift":"Shift",
		"Attendance":"Attendance",
		"RepresentativeReportAttendance":"Attendance",
		"Employment":"Employment",
		"Leave":"Leave",
		"EmploymentLeave":"Leave",
		"Rolling Off":"Rolling Off",
		"EmploymentRollingOff":"Rolling Off",
		"Employment Report":"Employment Report",
		"Shift":"Shift",
		"EmploymentReportShift":"Shift",
		"Attendance":"Attendance",
		"EmploymentReportAttendance":"Attendance",
		"Fingerprint Configuration":"Fingerprint Configuration",
		"Employee":"Employee",
		"FingerprintConfigurationEmployee":"Employee",
		"Non Payroll Employee":"Non Payroll Employee",
		"FingerprintConfigurationNonPayrollEmployee":"Non Payroll Employee",
		"Biometric":"Biometric",
		"FingerprintConfigurationBiometric":"Biometric",
		"Client":"Client",
		"FingerprintConfigurationClient":"Client",
		//OPTIONS
		"active":"active",
		"inactive":"inactive",
		"candidate":"candidate",
		"male":"male",
		"female":"female",
		"single":"single",
		"married":"married",
		"option1":"option1",
		"option2":"option2",
		"option3":"option3",
		"contract":"contract",
		"permanent":"permanent",
		"draft":"draft",
		"confirmed":"Confirmed",
		"first":"first",
		"second":"second",
		"third":"third",
		"open":"open",
		"close":"close",
		"complete":"complete",
		"cancelled":"cancelled",
		"waiting":"waiting",
		"finished":"finished",
		"client":"client",
		"master":"master",

		"General Information": "General Information",
		"Personal Information": "Personal Information",
		"Work Schedule": "Work Schedule",
		"Payroll": "Payroll",
		"Tax Information": "Tax Information",
		"BPJS Information": "BPJS Information",
		"System": "System",
	}
}

export default Translations
