import React, { Component } from 'react'
import ReportDownloadButton from "framework/components/ReportDownloadButton"
import PDFDownloadButton from 'framework/components/PDFDownloadButton'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class TaxReportAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: "",
			dialog 			: {
				open 	: false,
				payload	: null
			}
		}
	}
	
	render() {
		let buttons = []
		// buttons.push(<ReportDownloadButton
		// 	record={this.props.record}
		// 	model="inventory/inventoryTaxNonTaxReport"
		// />)
		
		return (
			<React.Fragment>
				{/* <PDFDownloadButton 
					url="inventory/dailyStockReportPDF/result.pdf" 
					record={this.props.record} 
					maxWidth="lg"
				/>	
				{buttons} */}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(TaxReportAction)
