import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Cookies from "js-cookie";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, withRouter } from "react-router-dom";
import cx from "classnames";
import { tl } from "framework/utils/Translator";

// @material-ui/core components
import { withStyles, Tooltip } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

import Icon from "@material-ui/core/Icon";
import UserIcon from "@material-ui/icons/Person";
import LanguageIcon from "@material-ui/icons/Language";
import CaretIcon from "@material-ui/icons/KeyboardArrowDown";
import { can } from "framework/utils/AccessRights";

import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

// core components
// import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import ConfirmDialog from "framework/components/ConfirmDialog";

import gbFlag from "assets/img/ENicon.png";
import idFlag from "assets/img/IDicon.png";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import Config from "company/Config";
import toRGBA from "framework/utils/toRGBA";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openLanguage: false,
      miniActive: true,
      confirmDialog:{
        open:false,
      },
      ...this.getCollapseStates(props.routes),
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleMouseOver = (event) => {
    const el = event.target;
    const childEl = el.querySelector(".longText");
    if (childEl) {
      const childWidth = childEl.getBoundingClientRect().width;
      if (childWidth > 140) {
        el.classList.add("animate");
      }
    }
  };

  handleMouseOut = (event) => {
    const el = event.target;
    el.classList.remove("animate");
  };

  handleLogout() {
    axios
      .post(
        this.props.Session.host + "/user/logout",
        {},
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("accessToken"),
          },
        },
      )
      .then(() => {
        Cookies.remove("accessToken", { path: "/" });
        Cookies.remove("refreshToken", { path: "/" });
        Cookies.remove("username", { path: "/" });
        this.props.history.push("/auth/login");
      });
  }

  isLinkActive = (splitPath) => {
    if (
      this.props.location.pathname.split("/").pop() !==
      splitPath.split("/").pop()
    ) {
      let pathArray = this.props.location.pathname.split("/");
      if (pathArray.length > 3) {
        return pathArray[2] === splitPath.split("/").pop();
      }
    }
    return (
      this.props.location.pathname.split("/").pop() ===
      splitPath.split("/").pop()
    );
  };

  isCollapseChildrenActive = (routes) => {
    let isRouteActive = false;
    routes.map((route) => {
      if (route.path.endsWith("/:id?")) {
        let pathArray = this.props.location.pathname.split("/");
        let routePath = route.path.split("/")[1];
        if (pathArray.length > 3) {
          if (pathArray[2] === routePath) {
            isRouteActive = true;
          }
        } else {
          let currentPath = this.props.location.pathname.split("/").pop();
          let routePath = route.path.split("/")[1];
          if (currentPath === routePath) {
            isRouteActive = true;
          }
        }
      } else {
        let currentPath = this.props.location.pathname.split("/").pop();
        let routePath = route.path.split("/").pop();
        if (currentPath === routePath) {
          isRouteActive = true;
        }
      }
    });
    return isRouteActive;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.isCollapseChildrenActive(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.split("/").pop() ===
      routeName.split("/").pop()
      ? "active"
      : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  handleChangeLanguage = (lang) => {
    var expiration_date = new Date();
    expiration_date.setFullYear(expiration_date.getFullYear() + 10);
    document.cookie =
      "lang=" + lang + "; path=/; expires=" + expiration_date.toUTCString();
    document.location.reload(true);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      let isMenuAllowed =
        [...(this.props.Session.menus || [])].indexOf(prop.menu) === -1;
      if (prop.redirect || prop.invisible || isMenuAllowed) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.customCollapseLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.isCollapseChildrenActive(
              prop.views,
            ),
          });

        // unused, changed to custom div component to make marquee effect

        // const itemText =
        //   classes.itemText +
        //   " " +
        //   cx({
        //     [classes.itemTextMini]:
        //       this.props.miniActive && this.state.miniActive,
        //     [classes.itemTextMiniRTL]:
        //       rtlActive && this.props.miniActive && this.state.miniActive,
        //     [classes.itemTextRTL]: rtlActive,
        //   });
        // const collapseItemText =
        //   classes.collapseItemText +
        //   " " +
        //   cx({
        //     [classes.collapseItemTextMini]:
        //       this.props.miniActive && this.state.miniActive,
        //     [classes.collapseItemTextMiniRTL]:
        //       rtlActive && this.props.miniActive && this.state.miniActive,
        //     [classes.collapseItemTextRTL]: rtlActive,
        //   });
        // const caret =
        //   classes.caret +
        //   " " +
        //   cx({
        //     [classes.caretRTL]: rtlActive,
        //   });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.itemCollapse]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <div
                className={classes.navLinkContainer}
                onMouseEnter={this.handleMouseOver}
                onMouseLeave={this.handleMouseOut}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
                <div className={classes.itemText} id="itemText">
                  <div className="clampedText">{prop.name}</div>
                  <div className="longText">{prop.name}</div>
                  {/* <ListItemText
										primary={rtlActive ? prop.rtlName : prop.name}
										// secondary={
										// 	<b
										// 		className={
										// 			caret +
										// 			" " +
										// 			(this.state[prop.state] ? classes.caretActive : "")
										// 		}
										// 	/>
										// }
										disableTypography={true}
										className={cx(
											{ [itemText]: prop.icon !== undefined },
											{ [collapseItemText]: prop.icon === undefined }
										)}
									/> */}
                </div>
                <CaretIcon
                  className={
                    "caretIcon " + (this.state[prop.state] ? "active" : "")
                  }
                />
              </div>
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes["red"]]: this.activeRoute(prop.path),
        });
      // unused to create custom marquee effect, component changed to custom div
      // const itemText =
      //   classes.itemText +
      //   " " +
      //   cx({
      //     [classes.itemTextMini]:
      //       this.props.miniActive && this.state.miniActive,
      //     [classes.itemTextMiniRTL]:
      //       rtlActive && this.props.miniActive && this.state.miniActive,
      //     [classes.itemTextRTL]: rtlActive,
      //   });
      // const collapseItemText =
      //   classes.collapseItemText +
      //   " " +
      //   cx({
      //     [classes.collapseItemTextMini]:
      //       this.props.miniActive && this.state.miniActive,
      //     [classes.collapseItemTextMiniRTL]:
      //       rtlActive && this.props.miniActive && this.state.miniActive,
      //     [classes.collapseItemTextRTL]: rtlActive,
      //   });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      const singleNavLink =
        classes.itemLink +
        " " +
        classes.navLinkLeft +
        cx({
          [" " + classes.singleNavLink]: !this.activeRoute(prop.path),
          [" " + classes["red"]]: this.activeRoute(prop.path),
        });
      let baseLink = "";
      let splitPath = prop.path;
      if (prop.path.endsWith("/:id?")) {
        let split = prop.path.split("/:");
        baseLink = prop.layout + split[0];
        splitPath = split[0];
      } else {
        baseLink = prop.layout + prop.path;
      }

      const linkOnlyParent = ["/dashboard", "/order-tracking"];
      let activeSidebarColor = Config.saasConfig
        ? this.props.Session.activeSidebarColor || null
        : Config.activeSidebarColor;
      let activeMenuShadowColor = Config.saasConfig
        ? this.props.Session.activeMenuShadowColor || null
        : Config.activeMenuShadowColor;
      let asatorindoMenus = {
        id: {
          WRHREPORT01: "Stok Barang",
          WRHREPORT02: "Stok Harian",
          ARREPORT03: "Giro Belum Cair",
        },
        en: {
          WRHREPORT01: "Item Stock",
          WRHREPORT02: "Daily Stock",
          ARREPORT03: "Outstanding Giro",
        },
      };
      let menuLabel = prop.name;
      let lang = Cookies.get("lang");
      if (
        can("menu.asatorindo") &&
        ["WRHREPORT01", "WRHREPORT02", "ARREPORT03"].includes(prop.menu)
      ) {
        menuLabel = asatorindoMenus[lang][prop.menu];
      }
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          <NavLink
            to={baseLink}
            onClick={(e)=>{
              let currentStack = this.props.View.activityStack[this.props.View.activityStack.length - 1]
              if (currentStack?.isEdited) {
                e.preventDefault()
                this.handlePromptDirtyForm(baseLink)
              }
            }}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
              { [singleNavLink]: linkOnlyParent.includes(prop.path) },
            )}
            {...(this.isLinkActive(splitPath) && activeSidebarColor
              ? {
                  style: {
                    backgroundColor: activeSidebarColor || "#e91e32",
                    boxShadow: `0 12px 20px -10px ${
                      activeMenuShadowColor
                        ? toRGBA(activeMenuShadowColor, "0.28")
                        : "rgba(238, 37, 46,.28)"
                    }, 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px ${
                      activeMenuShadowColor
                        ? toRGBA(activeMenuShadowColor, "0.2")
                        : "rgba(238, 37, 46,.2)"
                    }`,
                  },
                }
              : {})}
          >
            <div
              className={classes.navLinkContainer}
              onMouseEnter={this.handleMouseOver}
              onMouseLeave={this.handleMouseOut}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <div className={classes.itemText} id="itemText">
                <div className="clampedText">{menuLabel}</div>
                <div className="longText">{menuLabel}</div>
                {/* <ListItemText
								primary={rtlActive ? prop.rtlName : prop.name}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{ [collapseItemText]: prop.icon === undefined }
								)}
							/> */}
              </div>
            </div>
          </NavLink>
        </ListItem>
      );
    });
  };

  toggleConfirmLogout = () => {
    this.setState({
      confirmDialog:{
        open:true,
        title:tl("logout"),
        content:tl("logout.confirmationText"),
        handleYes:this.handleLogout,
        handleNo:this.closeConfirmationDialog
      }
    });
  };

  handlePromptDirtyForm = (path) => {
    this.setState({
      confirmDialog:{
        open:true,
        title:tl("unsavedChanges"),
        content:tl("unsavedChanges.confirmationText"),
        handleYes:()=>{
          this.closeConfirmationDialog()
          this.confirmRouteChange(path)
        },
        handleNo:this.closeConfirmationDialog
      }
    });
  }

  confirmRouteChange = (path) => {
    this.props.history.push(path)
  }

  closeConfirmationDialog = () => {
    this.setState({
      confirmDialog:{
        open:false
      }
    })
  }

  render() {
    const { classes, image, routes, bgColor, rtlActive } = this.props;
    //unused in favor to use custom component
    // const itemText =
    //   classes.itemText +
    //   " " +
    //   cx({
    //     [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
    //     [classes.itemTextMiniRTL]:
    //       rtlActive && this.props.miniActive && this.state.miniActive,
    //     [classes.itemTextRTL]: rtlActive,
    //   });
    // const caret =
    //   classes.caret +
    //   " " +
    //   cx({
    //     [classes.caretRTL]: rtlActive,
    //   });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    var user = (
      <div className={userWrapperClass}>
        {/* <UserIcon className={classes.itemIcon} style={{marginLeft:"25px",marginTop:"3px",marginRight:"10px"}}/> */}
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <div className={classes.userInfo}>
                <NavLink to="/admin/profile">
                  <div className="user">
                    <div className="avatar">
                      {Cookies.get("username") ? (
                        Cookies.get("username")[0].toUpperCase()
                      ) : (
                        <UserIcon className={classes.itemIcon} />
                      )}
                    </div>
                    <div className="info">
                      <div>{Cookies.get("username")}</div>
                      <small>{tl("viewProfile")}</small>
                    </div>
                  </div>
                </NavLink>
                <Tooltip title={tl("logout")}>
                  <div
                    className="logoutButton"
                    onClick={() => this.toggleConfirmLogout()}
                  >
                    <LogoutIcon className={classes.itemIcon} />
                  </div>
                </Tooltip>
              </div>
              {/* <ListItemText
								primary={Cookies.get("username")}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openAvatar ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/> */}
            </NavLink>
            {/* <Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="/admin/profile"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
										style={{display:"flex", alignItems:"center"}}
									>
										<span className={classes.collapseItemUser}>
											<UserIcon className={classes.itemIcon}/>
										</span>
										<ListItemText
											primary={tl("viewProfile")}
											disableTypography={true}
											className={collapseItemText}
											style={{marginTop:"5px"}}
										/>
									</NavLink>
								</ListItem>
							</List>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="#"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
										style={{display:"flex", alignItems:"center"}}
										onClick={()=>this.handleLogout()}
									>
										<span className={classes.collapseItemUser}>
											<LogoutIcon className={classes.itemIcon}/>
										</span>
										<ListItemText
											primary={tl("logout")}
											disableTypography={true}
											className={collapseItemText}
											style={{marginTop:"5px"}}
										/>
									</NavLink>
								</ListItem>
							</List>
						</Collapse> */}
          </ListItem>
        </List>
      </div>
    );
    var Languages = (
      <div className={userWrapperClass + " " + classes.langSwitcherContainer}>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <div
              className={classes.langSwitcher}
              onClick={() => this.openCollapse("openLanguage")}
            >
              <LanguageIcon className={classes.itemIcon} />
              <div className="label">{tl("languages")}</div>
              <CaretIcon
                className={
                  "caretIcon " + (this.state.openLanguage ? "active" : "")
                }
              />
            </div>
            {/* <NavLink
							to={"#"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openLanguage")}
						>
							<ListItemText
								primary={tl(Cookies.get("lang"),"languages")}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openLanguage ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " basicLinks " + classes.userItemText}
							/>
						</NavLink> */}
            <Collapse in={this.state.openLanguage} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                    onClick={() => this.handleChangeLanguage("en")}
                  >
                    <span className={collapseItemMini} style={{ opacity: "1" }}>
                      <img
                        style={{ borderRadius: "5px" }}
                        src={gbFlag}
                        alt={tl(Cookies.get("lang"), "English")}
                      />
                    </span>
                    <ListItemText
                      primary={tl(Cookies.get("lang"), "English")}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                    onClick={() => this.handleChangeLanguage("id")}
                  >
                    <span className={collapseItemMini} style={{ opacity: "1" }}>
                      <img
                        style={{ borderRadius: "5px" }}
                        src={idFlag}
                        alt={tl(Cookies.get("lang"), "Indonesian")}
                      />
                    </span>
                    <ListItemText
                      primary={tl(Cookies.get("lang"), "Indonesian")}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );
    var sidebarLogo = Config.saasConfig
      ? this.props.Session.sidebarLogo || null
      : Config.sidebarLogo || null;
    var brand = (
      <div className="logo-container admin-page">
        {sidebarLogo && <img alt="Sidebar Logo" src={sidebarLogo} />}
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={Languages}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <ConfirmDialog
          open={this.state.confirmDialog.open}
          title={this.state.confirmDialog.title}
          content={this.state.confirmDialog.content}
          handleYes={this.state.confirmDialog.handleYes}
          handleNo={this.state.confirmDialog.handleNo}
        />
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(sidebarStyle)(Sidebar)));
